/*
 * This code and all components (c) Copyright 2019-2020, Wowza Media Systems, LLC. All rights reserved.
 * This code is licensed pursuant to the BSD 3-Clause License.
 */

 html {
  height: 100%;
 }
 body {
   background: #F8F8F8;
   background-image: url(./images/bg-image-1.svg), url(./images/bg-image-2.svg);
   background-position: bottom center, top center;
   background-repeat: no-repeat;
   background-size: 100% 32%, 100% 60%;
   height: 100%;
 }
 a:disabled,
 button:disabled {
   cursor: not-allowed;
 }

 .alert-dismissible .close {
   padding: .4rem 1.25rem;
 }

 #top-nav {
   background: #FFFFFF;
   box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
 }

 #content {
   margin-top: 3em;
   max-width: 1500px;
 }
 #player-video,
 #publisher-video {
   width: 100%    !important;
   height: auto   !important;
   background: #EEEEEE;
   border-radius: 10px;
   position: relative;
   left: 50%;
   transform: translateX(-50%);
 }

.meeting-peer-player {
  width: 45%    !important;
  height: auto   !important;
  background: #EEEEEE;
  border-radius: 10px;
  margin: 15px 15px;
}

 #video-live-indicator {
   position: absolute;
   top: 0.5em;
   left: 1.5em;
   opacity: 0.9;
 }

 #build {
   position:absolute;
   color:#ccc;
   font-size:50%;
   bottom:1em;
   right:1em;
 }

/* --- */

/*
 * This code and all components (c) Copyright 2019-2020, Wowza Media Systems, LLC. All rights reserved.
 * This code is licensed pursuant to the BSD 3-Clause License.
 */

 #play-video-container {
  height:0;
  width:100%;
  padding-bottom:57%;
  background-color:rgba(102, 102, 102, 1);
  border-radius:0.75em;
}

#publish-content,
#play-content,
#meeting-content,
#composite-content {
  max-width: 1500px;
}

#play-settings,
#publish-settings,
#meeting-settings,
#composite-content-inner {
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 1em;
}
#composite-content-outer {
  padding:0 1em;
}
#play-toggle,
#publish-toggle,
#meeting-toggle {
  background: #FF8400;
  border-radius: 55px;
  width: 100%;
  font-weight: 500;
  font-size: 17px;
  line-height: 130%;
  text-align: center;
  color: #FFFFFF;
}


#navbarNav {
  height: 40px;
}

#play-share-link,
#publish-share-link,
#meeting-share-link {
  padding: 0.25em 0.7em;
}
#play-share-link,
#publish-share-link:focus {
  outline: none;
}

.control-button {
  background: #FFFFFF;
  border: 1px solid #B2B3B5;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 2em;
}

ul.navbar-nav {
  flex-direction: unset;
}

.navbar-nav li.nav-item.page {
  text-align: center;
}


.navbar-nav li.nav-item.page.active span{
  border-bottom: solid 3px  #65686A;
}
.navbar-nav li.nav-item.page span {
  padding: 0 40px 0 40px;
  position: relative;
  left: 0;
  display: block;
  top: 20px;
  border-bottom: none;
}

.navbar-nav li.nav-item a{
  text-decoration: none;
  color: #939597;
}
.navbar-nav li.nav-item.active a{
  color: #65686A;
}

#nav-publish-indicator {
  display: inline;
  margin-right:0.5em;
  color: #dc3545;
}
